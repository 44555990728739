import { Injectable } from '@angular/core';
import { filter, from, map, mergeMap, shareReplay } from 'rxjs';
import {
  IChannelUpdateDeal,
  IChannelUpdateQuote,
  IChannelUpdateTariff,
  IChannelUpdateTraderTariff,
} from '@prlw/core/sse/sse.gateway';
import {
  EventSourceService,
  eventGuard,
  isMessageEvent,
  isOpenEvent,
} from '@prlw/libs/event-source/event-source.service';

@Injectable()
export class SseGateway {
  constructor(private readonly eventSourceService: EventSourceService) {}

  unicast$ = this.eventSourceService
    .createEventSource<IChannelUpdateTariff>(`/api/sse/unicast`)
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  unicastMessage$ = this.unicast$.pipe(
    filter(isMessageEvent),
    map((event) => event.data),
  );
  unicastOpened$ = this.unicast$.pipe(filter(isOpenEvent));

  multicast$ = this.eventSourceService
    .createEventSource<
      IChannelUpdateQuote | IChannelUpdateDeal | IChannelUpdateTraderTariff
    >(`/api/sse/multicast`)
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );
  multicastMessage$ = this.multicast$.pipe(
    filter(isMessageEvent),
    map((event) => event.data),
  );
  messageIChannelUpdateTraderTariff$ = this.multicastMessage$.pipe(
    mergeMap((items) => from(items)),
    filter(
      eventGuard<IChannelUpdateTraderTariff>('IChannelUpdateTraderTariff'),
    ),
  );
}
